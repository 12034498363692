export default {
    methods: {
        // get pagination data
        get_paginate(url, params = null, allData = null,) {
            this.$root.spinner =  true ;
            this.$root.tableSpinner = true;

            let apiUrl = `${process.env.VUE_APP_API_BASE_URL}/${url}`;
            // if (this.$route.query.page) {
            //     apiUrl = url + "?page=" + this.$route.query.page;
            // }

            this.axios
                .get(apiUrl, { params: params })
                .then((res) => {
                    if (allData) {
                        this.extraData[allData] = res.data;
                    } else {
                        console.log('crud ', res.data.data.data);
                        this.table['datas'] = res.data.data;
                        this.table['meta'] = res.data.data;
                        this.table['links'] = res.data.data.links;
                    }
                    setTimeout(() => {
                        this.$root.spinner = false
                        this.$root.tableSpinner = false
                    }, 200);
                })
                .catch((error) => {
                    console.log(error);
                    this.$root.spinner = false
                    this.$root.tableSpinner = false
                })
        },

        // get single data
        get_data(url, dataVar = null) {
            this.spinner = true;
            this.axios
                .get(`${process.env.VUE_APP_API_BASE_URL}/${url}`,)
                .then((res) => {
                    if (dataVar) {
                        this.obj[dataVar] = res.data.data;
                    } else {
                        this.data = res.data.data;
                    }
                    setTimeout(() => this.$root.spinner = false, 200);
                })
                .catch(error => {
                    this.$root.spinner = false, console.log(error)
                } )
        },



        // Get Promise Request
        callApi(method, url, dataObj = null) {
            this.$root.spinner = true;
            try {
                return this.axios({
                    method: method,
                    url: `${process.env.VUE_APP_API_BASE_URL}/${url}`,
                    data: dataObj,
                })
            } catch (e) {
                return e.response;
            }
        },



        // get sorting
        get_sorting(namespace) {
            try {
                this.axios.get("/get-last-sorting", { params: { model: namespace } })
                    .then(res => {
                        if (res.data) {
                            this.data['sorting'] = res.data;
                        }
                    })
            } catch (e) {
                return e.response;
            }
        },


        // store data
        store(model_name, data, redirect = null) {
            this.$root.validation_errors = {};
            this.$root.submit = true;
            console.log('data store', data);
            this.axios.post("/" + model_name, data)
                .then(res => {
                    if (res.status == 200) {
                        this.$toast(res.data.message, "success");
                    } else if (res.status == 203) {
                        this.$toast(res.data.message, "warning");
                    }else if(res.status == 201){
                        this.validateError(res.data.data)
                        this.$toast("Validation fails", "warning");
                        return false;
                    }

                    let url = redirect ? redirect : model_name + '.index';
                    this.$router.push({ name: url });
                })
                .catch(error => {
                    console.log(error);
                    // for (const [key, value] of Object.entries(error.response.data.data)) {
                    //     console.log(key, value);
                    //     this.data[key]['error'] = value;
                    //     // this.validation.firstError[key] =  value
                    // }
                    // if (error.response.status === 422) {
                    //     // $("#validateModal").modal("show");
                    //     if (error.response.data.exception) {
                    //         this.$root.exception_errors = error.response.data.exception
                    //     } else {
                    //         this.$root.validation_errors = error.response.data.errors || {};
                    //     }

                    //     this.$toast("Something Went wrong", "warning");
                    // }
                })
                .then(alw => {
                    console.log(alw);
                    setTimeout(() => (this.$root.submit = false), 100)
                });
        },

        validateError(errors){
            for (const [key, value] of Object.entries(errors)) {
                this.error[key] = value[0];               
            }
        },

        // update data
        update(model_name, data, id, image = null, redirect = null) {
            this.$root.validation_errors = {};
            this.$root.submit = true;
            if (image) {
                data.append("_method", "put");
                data.append("id", id);
                this.axios.post("/" + model_name + "/" + id, data)
                    .then(res => {
                        if (res.status == 201) {
                            this.validateError(res.data.data)
                            this.$toast("Validation fails", "warning");
                            return false;
                        } else if (res.status == 202) {
                            this.$toast(res.data.message, "error");
                        } else if (res.status == 203) {
                            this.$toast(res.data.message, "warning");
                        }
                        if (res.status == 200) {
                            this.$toast(res.data.message, "success");
                        }
                        console.log(redirect);
                        // let url = redirect ? redirect : model_name + '.index';
                        // this.$router.push({ name: url, query: { page: this.$route.query.page, id: id }, })
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            // $("#validateModal").modal("show");
                            if (error.response.data.exception) {
                                this.$root.exception_errors = error.response.data.exception
                            } else {
                                this.$root.validation_errors = error.response.data.errors || {};
                            }
                            this.$toast("You need to fill empty mandatory fields", "warning");
                        }
                    })
                    .then(alw => {
                        console.log(alw);
                        setTimeout(() => (this.$root.submit = false), 400)
                    });
            }
            else {
                this.axios.put("/" + model_name + "/" + id, data)
                    .then(res => {
                        if (res.status == 200) {
                            this.$toast(res.data.message, "success");
                        } 
                        else  if (res.status == 201) {
                            this.validateError(res.data.data)
                            this.$toast("Validation fails", "warning");
                            return false;
                        } else if (res.status == 203) {
                            this.$toast(res.data.message, "warning");
                        }
                        // all success then error message blank
                        this.error = {}

                        // let url = redirect ? redirect : model_name + '.index';
                        // this.$router.push({ name: url, query: { page: this.$route.query.page, id: id }, })
                    })
                    .catch(error => {
                        if (error.response?.status === 422) {
                            if (error.response.data.exception) {
                                this.$root.exception_errors = error.response.data.exception
                            } else {
                                this.$root.validation_errors = error.response.data.errors || {};
                            }
                            this.$toast("You need to fill empty mandatory fields", "warning");
                        }
                    })
                    .then(alw => {
                        console.log(alw);
                        setTimeout(() => (this.$root.submit = false), 400)
                    });
            }
        },

        // destroy data
        destroy_data(model_name, id, search_data) {
            this.$root.tableSpinner = true;
            let url = model_name + "/" + id;
            this.axios
                .delete(`${process.env.VUE_APP_API_BASE_URL}/${url}`)
                .then(res => {
                    console.log(res);
                    this.get_paginate(model_name, search_data)
                    if (res.status == 200) {
                        this.$toast(res.data.message, "success");
                    } else if (res.status == 201) {
                        this.$toast(res.data.message, "error");
                    }
                })
                .catch(error => console.log(error))
                .then(alw => {
                    console.log(alw);
                    setTimeout(() => (this.$root.tableSpinner = false), 200)
                });
        },

        // get route name
        getRouteName(model) {
            this.table.routes = {
                view: model + ".show",
                edit: model + ".edit",
                destroy: model + ".destroy"
            };
        },

        getDistrict(division_id){
            console.log(division_id);
            return this.axios.get('/district');
        }
    }
}
