<template>
    <div class="authentication-wrapper authentication-cover">
        <!-- Logo -->
        <a href="index.html" class="app-brand auth-cover-brand">
            <span class="app-brand-logo demo">
                <span class="text-primary">
                    <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z"
                            fill="currentColor"></path>
                        <path opacity="0.06" fill-rule="evenodd" clip-rule="evenodd"
                            d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z" fill="#161616">
                        </path>
                        <path opacity="0.06" fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z" fill="#161616">
                        </path>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z"
                            fill="currentColor"></path>
                    </svg>
                </span>
            </span>
            <span class="app-brand-text demo text-heading fw-bold">Autocommerce</span>
        </a>
        <!-- /Logo -->
        <div class="authentication-inner row m-0">
            <!-- /Left Text -->
            <div class="d-none d-xl-flex col-xl-8 p-0">
                <div class="auth-cover-bg d-flex justify-content-center align-items-center">
                    <img src="https://demos.pixinvent.com/vuexy-html-admin-template/assets/img/illustrations/auth-login-illustration-light.png"
                        alt="auth-login-cover" class="my-5 auth-illustration"
                        data-app-light-img="illustrations/auth-login-illustration-light.png"
                        data-app-dark-img="illustrations/auth-login-illustration-dark.png"
                        style="visibility: visible;">
                    <img src="https://demos.pixinvent.com/vuexy-html-admin-template/assets/img/illustrations/bg-shape-image-light.png"
                        alt="auth-login-cover" class="platform-bg"
                        data-app-light-img="illustrations/bg-shape-image-light.png"
                        data-app-dark-img="illustrations/bg-shape-image-dark.png" style="visibility: visible;">
                </div>
            </div>
            <!-- /Left Text -->

            <!-- Login -->
            <div class="d-flex col-12 col-xl-4 align-items-center authentication-bg p-sm-12 p-6">
                <div class="w-px-400 mx-auto mt-12 pt-5">
                    <h4 class="mb-1">Welcome to Autocommerce! 👋</h4>
                    <p class="mb-6">Please sign-in to your account and start the adventure</p>
           
                    <form>
                        <div class="alert alert-danger" v-if="errormessage">
                            {{ errormessage }}
                        </div>
                        <div class="form-group">
                            <label for="email">Email address</label>
                            <input v-model="email" :class="validation.hasError('email') ? 'border-danger' : ''" class="form-control form-control-rounded" id="email" type="email">
                            <small class="message text-danger">{{ validation.firstError('email') }}</small>
                        </div>
                        <div class="form-group">
                            <label for="password">Password</label>
                            <input v-model="password" :class="validation.hasError('password') ? 'border-danger' : ''" class="form-control form-control-rounded" id="password" type="password">
                            <small class="message text-danger">{{ validation.firstError('password') }}</small>

                        </div>
                        <button  @click.prevent="submit()" class="btn btn-rounded btn-primary btn-block mt-2"
                            :disabled='spinner ? true : false'>
                            <span v-if='spinner'>
                                <i class='fa fa-spinner fa-spin'></i>
                                processing...
                            </span>
                            <span v-else> Sign In </span>
                        </button>
                    </form>

                    <!-- <p class="text-center">
                        <span>New on our platform?</span>
                        <a href="auth-register-cover.html">
                            <span>Create an account</span>
                        </a>
                    </p> -->

                    <div class="divider my-6">
                        <div class="divider-text">support</div>
                    </div>

                    <div class="d-flex justify-content-center text-center">
                        For any assistance, please contact us at: <br>
                        Phone: 01767404822
                    </div>
                </div>
            </div>
            <!-- /Login -->
        </div>
    </div>
</template>

<script>
import { Validator } from 'simple-vue-validator'

export default {
    name: 'Login',

    data() {
        return {
            email: null,
            password: null,
            errormessage: null
        }
    },
    methods: {
        submit() {
            this.spinner = true;

            this.$validate().then((res) => {
                const error = this.validation.countErrors();
                // If there is an error
                if (error > 0) {
                    console.log(this.validation.allErrors());
                    this.spinner = false;
                    return false;
                }

                // If there is no error
                if (res) {
                    // code
                    console.log(process.env.VUE_APP_API_BASE_URL);

                    this.axios.post('/login', { email: this.email, password: this.password })
                        .then(res => {
                            if (res.status == 200) {
                                console.log(res.data);
                                this.$store.dispatch("auth/loginStore", res.data.data.user);
                                localStorage.setItem('accessToken', res.data.data.access_token)
                                localStorage.setItem('user', JSON.stringify(res.data.data.user))
                                window.location.href = '/';
                            }
                        }).catch(err => {
                            console.log(err);
                            this.errormessage = err.response.data.message
                            this.spinner = false;
                        })
                }
            });
        }
    },

    created() {
        let token = localStorage.accessToken;
        if (token != undefined) {
            window.location.href = '/';
        }
    },
    validators: {
        email: function (value) {
            return Validator.value(value).required('email is require').email();
        },
        password: function (value) {
            return Validator.value(value).required('password is require');
        },
    }
}

</script>

<style scoped>

.authentication-wrapper {
    --bs-auth-basic-inner-max-width: 460px;
    display: flex;
    flex-basis: 100%;
    inline-size: 100%;
    min-block-size: 100vh
}

.authentication-wrapper .authentication-bg {
    background-color: #fff
}

@media(max-width: 1199.98px) {
    .authentication-wrapper:not(.authentication-basic) .authentication-inner {
        block-size: 100vh
    }
}

.authentication-wrapper .authentication-inner {
    block-size: 100%;
    inline-size: 100%;
    margin-block: auto;
    margin-inline: 0
}

.authentication-wrapper .authentication-inner .auth-cover-bg {
    position: relative;
    block-size: 100vh;
    inline-size: 100%
}

.authentication-wrapper .authentication-inner .auth-cover-bg .auth-illustration {
    z-index: 1;
    max-block-size: 65%;
    max-inline-size: 65%
}

.authentication-wrapper .authentication-inner .platform-bg {
    position: absolute;
    block-size: 35%;
    inline-size: 100%;
    inset-block-end: 0%;
    inset-inline-start: 0%
}

.authentication-wrapper .authentication-inner .auth-multisteps-bg-height {
    block-size: 100vh
}

.authentication-wrapper .authentication-inner .auth-multisteps-bg-height>img:first-child {
    z-index: 1
}

.authentication-wrapper.authentication-basic {
    align-items: center;
    justify-content: center
}

.authentication-wrapper.authentication-basic .authentication-inner {
    position: relative;
    max-inline-size: var(--bs-auth-basic-inner-max-width)
}

.authentication-wrapper.authentication-basic .authentication-inner::before {
    position: absolute;
    background: var(--bs-primary);
    block-size: 233px;
    content: " ";
    inline-size: 238px;
    inset-block-start: -35px;
    inset-inline-start: -45px;
    mask-image: url("data:image/svg+xml,%3Csvg width='238' height='233' viewBox='0 0 238 233' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='88.5605' y='0.700195' width='149' height='149' rx='19.5' stroke='%237367F0' stroke-opacity='0.16'/%3E%3Crect x='0.621094' y='33.761' width='200' height='200' rx='10' fill='%237367F0' fill-opacity='0.08'/%3E%3C/svg%3E%0A");
    mask-repeat: no-repeat;
    mask-size: 100% 100%
}

@media(max-width: 575.98px) {
    .authentication-wrapper.authentication-basic .authentication-inner::before {
        display: none
    }
}

:dir(rtl) .authentication-wrapper.authentication-basic .authentication-inner::before {
    inset-inline-start: -70px
}

.authentication-wrapper.authentication-basic .authentication-inner::after {
    position: absolute;
    z-index: -1;
    background: var(--bs-primary);
    block-size: 180px;
    content: " ";
    inline-size: 180px;
    inset-block-end: -30px;
    inset-inline-end: -56px;
    mask-image: url("data:image/svg+xml,%3Csvg width='180' height='180' viewBox='0 0 180 180' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1.30469' y='1.44312' width='178' height='178' rx='19' stroke='%237367F0' stroke-opacity='0.16' stroke-width='2' stroke-dasharray='8 8'/%3E%3Crect x='22.8047' y='22.9431' width='135' height='135' rx='10' fill='%237367F0' fill-opacity='0.08'/%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    mask-size: 100% 100%
}

@media(max-width: 575.98px) {
    .authentication-wrapper.authentication-basic .authentication-inner::after {
        display: none
    }
}

.authentication-wrapper.authentication-basic .authentication-inner .card {
    z-index: 1
}

@media(min-width: 576px) {
    .authentication-wrapper.authentication-basic .authentication-inner .card {
        padding: 1.5rem
    }
}

.authentication-wrapper.authentication-basic .authentication-inner .card .app-brand {
    margin-block-end: 1.5rem
}

.authentication-wrapper .auth-input-wrapper .auth-input {
    font-size: 150%;
    max-inline-size: 50px;
    padding-inline: .4rem
}

@media(max-width: 575.98px) {
    .authentication-wrapper .auth-input-wrapper .auth-input {
        font-size: 1.125rem
    }
}

.auth-cover-brand {
    position: absolute;
    z-index: 1;
    inset-block-start: 2.5rem;
    inset-inline-start: 1.5rem
}

#twoStepsForm .fv-plugins-bootstrap5-row-invalid .form-control {
    border-width: 2px;
    border-color: #ff4c51;
    box-shadow: none
}

@media(max-width: 575.98px) {
    .numeral-mask-wrapper .numeral-mask {
        padding: 0
    }

    .numeral-mask {
        margin-inline: 1px
    }
}

</style>