<template>
  <div class="row pt-3" v-if="table.meta && Object.keys(table.meta).length > 0">
    <div class="col-6">
      Showing {{ table.meta.from }} to {{ table.meta.to }} of
      {{ table.meta.total }} entries
    </div>

    <div class="col-6 d-flex justify-content-end">
      <nav class="dataTable-pagination">
        <ul class="pagination">
          <li v-if="table.meta.last_page > 1" class="pager page-item"
            :class="table.meta.current_page == 1 ? 'disabled' : ''">
            <a class="page-link" @click="get_datas(1)">
              {{ '<' }} </a>
          </li>
          
          <template v-for="leftNum in decrement()" :key="leftNum">
            <li class="page-item">
              <a class="page-link" v-if="leftNum > 0" href="javascript:void(0)" @click="get_datas(leftNum)">
                <span>{{ leftNum }} </span>
              </a>
            </li>
          </template>


          <li class="page-item" :class="(table.meta.current_page == this.$route.query.page || 1) ? 'active' : ''">
            <!-- Current Page -->
            <a class="page-link" @click="get_datas(table.meta.current_page)">
              <span> {{ table.meta.current_page }} </span>
            </a>
          </li>


          <!-- Right Numbers -->
          <template v-for="rightNum in 6" :key="rightNum">
            <li class="page-item">
              <a class="page-link" v-if="
                table.meta.current_page + rightNum <= table.meta.last_page
              " href="javascript:void(0)" @click="get_datas(table.meta.current_page + rightNum)">
                <span>{{ table.meta.current_page + rightNum }}</span>
              </a>
            </li>
          </template>
          <li class="page-item" @click="get_datas(table.meta.current_page +1)">
            <a class="page-link" href="#" data-page="2">
            ›
          </a></li>
        </ul>
      </nav>
    </div>
  </div>

</template>

<script>
export default {
  name: "Pagination",

  inject: ["table", "search_data", "model"],

  props: {
    extra_data: Object,
  },

  methods: {
    get_datas: function (page_index) {
      let page = page_index ? page_index : this.$route.query.page;

      if (this.table.customeRoute) {
        this.$router.push({ name: this.table.customeRouteName, query: { page: page } });
      } else {
        this.$router.push({ name: this.model + ".index", query: { page: page } });
      }

      var url ;
      if (this.$route.query.page) {
        url = this.model + "?page=" + page;
      }else{
        url = this.model + "?page=" + page;
      }

  
      let mergedObj = { ...this.search_data, ...this.extra_data ?? {} };
      
      this.$root.spinner = true;
      this.get_paginate(`${url}`, mergedObj);
    },

    // Decrement Page Loop
    decrement() {
      let curentPage = this.table.meta.current_page - 1;
      let arr = [];
      for (var i = 1; i <= 6; i++) {
        if (curentPage > 0) {
          arr.unshift(curentPage--);
        }
      }
      return arr;
    },
  },

  created() {
    console.log('hit paginate', this.table);
  }
};
</script>

<style scoped>

.active span{
  color: white !important
}
</style>